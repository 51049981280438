import React, { useRef, useEffect } from 'react';
import { Modal } from 'bootstrap';

function ModalPrematriculado() {

    const modalRef = useRef();

    const showModal = () => {
        const modalEle = modalRef.current
        const bsModal = new Modal(modalEle, {
            backdrop: 'static',
            keyboard: false
        })
        bsModal.show();
    }

    const hideModal = () => {
        const modalEle = modalRef.current
        const bsModal = Modal.getInstance(modalEle)
        bsModal.hide();
    }

    useEffect(() => {
        showModal();
    }, [])

    return(<> 
    
        <div className="modal fade" ref={modalRef} tabIndex="-1">    
            <div className="modal-dialog modal-lg">
                <div className="modal-content">
                    <div className="modal-header bg-success text-white">
                        <h5 className="modal-title" id="staticBackdropLabel">¡Pre-Matrícula realizada con éxito!</h5>
                        <button type="button" className="btn-close" onClick={hideModal} aria-label="Close"></button>
                    </div>                   
                    <div className="modal-body">
                        <div className="row">
                            <h6>
                            &middot; Nuestro equipo de Admisión se pondrá en contacto contigo a la mayor brevedad posible.
                            <br /><br />
                            &middot; Te invitamos a estar atento y atenta a tu correo electrónico para recibir toda la información sobre fechas de postulaciones a beneficios y matrículas 2025.
                            </h6>
                        </div>                      
                    </div>
                    <div className="modal-footer">                                                                           
                        <button type="button" className="btn btn-gris btn-sm" onClick={hideModal} >
                            Cerrar
                        </button>                                    
                    </div>
                </div>
            </div>              
        </div>    
    </>)


} 
export default ModalPrematriculado;